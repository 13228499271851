<template>
  <el-row class="table">
    <el-tabs type="card" class="tb-tabs tabs-bg">
      <el-tab-pane disabled>
        <span slot="label"><h3>题集管理</h3></span>
      </el-tab-pane>
    </el-tabs>
    <el-row class="searchBg" :gutter="24">
      <el-col :span="4" class="flex-col">
        <span>题集标题：</span>
        <el-input
          size="small"
          placeholder="输入题集名称"
          v-model.trim="searchForm.moduleTitle"
          @keyup.enter.native="search"
        >
        </el-input>
      </el-col>
      <el-col class="flex-col" :span="4">
        <span>题集ID：</span>
        <el-input
          style="width: 150px"
          size="small"
          placeholder="输入题库ID"
          onkeyup="value=value.replace(/[^\d]/g,'')"
          v-model.trim="searchForm.creatorName"
          @keyup.enter.native="search"
        >
        </el-input>
      </el-col>

      <el-col class="flex-col" :span="4">
        <span>状态：</span>
        <el-select
          v-model="searchForm.defunct"
          placeholder="请选择状态"
          size="small"
        >
          <el-option
            v-for="item in defuncteList"
            :key="item.id"
            :label="item.state"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-col>
      <el-col class="flex-col" :span="4">
        <span>标签：</span>
        <el-cascader
          :show-all-levels="false"
          style="width: 170px"
          size="small"
          v-model="searchForm.tagIdSet"
          ref="tagIdSet"
          :options="tagTreeData"
          collapse-tags
          placeholder="请选择标签"
          :props="props"
          filterable
          clearable
        ></el-cascader>
      </el-col>
      <el-col class="flex-col" :span="3">
        <el-button type="primary" size="small" @click="search">搜 索</el-button>
        <el-button size="small" @click="reset">重 置</el-button>
      </el-col>
    </el-row>
    <el-row class="operationClass">
      <!-- <el-radio-group
        v-model="searchForm.raceType"
        size="mini"
        @input="changeRaceType"
      >
        <el-radio-button label="刷题题集"></el-radio-button>
      </el-radio-group> -->
      <el-button style="margin-left: 30px" size="mini " @click="add"
        >新建题集
      </el-button>
    </el-row>
    <el-table :data="dataList" class="tb-list">
      <el-table-column
        label="题集ID"
        align="left"
        prop="contest_id"
        width="100"
      ></el-table-column>
      <el-table-column label="题集标题" width="150">
        <template slot-scope="scope">
          <el-link @click="gotodetail(scope.row)">
            <span style="color: #1890ff">{{ scope.row.title }}</span>
          </el-link>
        </template>
      </el-table-column>
      <el-table-column label="类型(赛制)" align="left" width="180">
        <template v-slot="{ row }">
          <span>
            {{ searchForm.raceType }}
            <span v-if="searchForm.raceType === '普通题集'">
              ({{ row.raceRule }})
            </span>
          </span>
        </template>
      </el-table-column>
      <el-table-column label="题集标签">
        <template slot-scope="scope">
          <el-row style="max-height: 80px; overflow: auto">
            <el-tag
              class="c-margin-left-8 c-margin-top-8"
              v-for="(item, index) in scope.row.tagInfoList"
              :key="index"
              type="warning"
              >{{ item.title }}
            </el-tag>
          </el-row>
        </template>
      </el-table-column>

      <el-table-column label="状态" align="left" width="80">
        <template slot-scope="scope">
          <span
            v-text="getState(scope.row.defunct)"
            :style="'color' + ':' + getStateColor(scope.row.defunct)"
          ></span>
        </template>
      </el-table-column>
      <el-table-column label="比赛时间" align="left" width="360">
        <template slot-scope="scope">
          <span
            v-if="
              scope.row.startTime &&
              scope.row.startTime.indexOf('0001-01-01') === -1
            "
            >{{ scope.row.startTime.substring(0, 19) }} ~
            {{ scope.row.endTime.substring(0, 19) }}</span
          >
          <span v-else>不限时</span>
        </template>
      </el-table-column>

      <el-table-column label="操作" align="left" width="120">
        <template slot-scope="scope">
          <el-dropdown trigger="click">
            <el-button type="text">
              操 作
              <i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                :disabled="
                  scope.row.role !== '管理者' && scope.row.role !== '创建者'
                "
                v-if="scope.row.defunct === 'Y'"
                @click.native="updateQuestionState(scope.row, 'N')"
              >
                <span>发布</span>
              </el-dropdown-item>
              <el-dropdown-item
                :disabled="
                  scope.row.role !== '管理者' && scope.row.role !== '创建者'
                "
                v-if="scope.row.defunct === 'N'"
                @click.native="updateQuestionState(scope.row, 'Y')"
              >
                <span>下架</span>
              </el-dropdown-item>
              <el-dropdown-item
                :disabled="
                  scope.row.role !== '管理者' && scope.row.role !== '创建者'
                "
                @click.native="copyContest(scope.row)"
              >
                <span>复制</span>
              </el-dropdown-item>
              <el-dropdown-item
                :disabled="
                  scope.row.role !== '管理者' && scope.row.role !== '创建者'
                "
                @click.native="deleteItem(scope.row)"
              >
                <span>删除</span>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pagination.currentPage"
      :page-sizes="pagination.pageSizes"
      :page-size="pagination.pageSize"
      :layout="pagination.layout"
      :total="pagination.total"
    ></el-pagination>
  </el-row>
</template>

<script>
import {
  getRaceContestList,
  updateQuestionState,
} from "@/api/research/contest";
import { getTagGroupList, getTags } from "@/api/research/tag";

export default {
  name: "contestManage",
  watch: {
    $route: function (to, from) {
      if (from.path == "/operation/addcontest" && to.query.type == "新增") {
        this.getRaceContestList();
        let latestView = { path: "/operation/contestManage" };
        this.$router.push(latestView);
      }
    },
  },
  data() {
    return {
      searchForm: {
        tagIdSet: [],
        defunct: "",
        raceRule: "",
        moduleTitle: "",
        creatorName: "",
        raceType: "刷题题集", // 题集类型
      },
      tagTreeData: [],
      props: { multiple: true, value: "id", label: "title" },
      tagIdSet: [],
      controls: false,
      defunct: "",
      defuncteList: [
        {
          id: "Y",
          state: "未发布",
        },
        {
          id: "N",
          state: "已发布",
        },
      ],
      modelState: "",
      modelStateList: [
        {
          name: "ACM",
        },
        {
          name: "OI",
        },
        {
          name: "IOI",
        },
        // {
        //   name: 'CA'
        // },
        // {
        //   name: '极速题集'
        // }
      ],

      dataList: [
        {
          ID: 123,
        },
      ],
      copyType: false,
      contestId: "",
      pagination: {
        currentPage: 1,
        pageSize: 10,
        pageSizes: [10, 30, 50, 100],
        layout: "total, sizes, prev, pager, next, jumper",
        total: 0,
      },

      moduleTitle: "",
      creatorName: "",
    };
  },
  created() {
    this.getRaceContestList();
    this.getTagGroupList();
  },
  methods: {
    changeRaceType() {
      this.searchForm.raceRule = "";
      this.search();
    },
    getTagGroupList() {
      getTagGroupList({
        pageNum: 1,
        pageSize: 100,
        businessType: "题集",
      }).then((res) => {
        if (res.state === "success") {
          this.tagGroupList = res.body.list;
          const tagTreeData = [];
          this.tagGroupList.forEach((ele, idx) => {
            tagTreeData.push({
              ...ele,
            });
          });
          getTags({
            pageNum: 1,
            pageSize: 10000,
            businessType: "题集",
          }).then((res) => {
            if (res.state === "success") {
              this.tagList = res.body;
              tagTreeData.forEach((ele, idx) => {
                this.tagList.forEach((item, index) => {
                  if (item.groupId === ele.id) {
                    item.showEdit = false;
                    if (!ele.children) {
                      ele.children = [];
                      ele.children.push({
                        ...item,
                      });
                    } else {
                      ele.children.push({
                        ...item,
                      });
                    }
                  }
                });
              });
              this.tagTreeData = tagTreeData;
              console.log("tagTreeData", this.tagTreeData);
            }
          });
        }
      });
    },
    getState(state) {
      switch (state) {
        case "Y":
          return "未发布";
        case "N":
          return "已发布";
        default:
          return "未知状态";
      }
    },
    getStateColor(state) {
      switch (state) {
        case "Y":
          return "#CE433F";
        case "N":
          return "#4DA54D";
        default:
          return "#1890FF";
      }
    },
    gotodetail(row) {
      this.$router.push({
        path: "/operation/contest-detail",
        query: {
          contestId: row.contest_id,
        },
      });
    },
    // 获取模板列表
    async getRaceContestList() {
      const response = await getRaceContestList({
        pageNum: this.pagination.currentPage,
        pageSize: this.pagination.pageSize,
        needCount: true,
        title: this.searchForm.moduleTitle,
        contest_id:
          this.searchForm.creatorName === ""
            ? null
            : this.searchForm.creatorName,
        raceRule: this.searchForm.raceRule,
        raceType: this.searchForm.raceType,
        defunct: this.searchForm.defunct,
        orderBy: "t1.createTime desc",
        tagIdSet: this.searchForm.tagIdSet.map((item) => {
          return item[item.length - 1];
        }),
      });
      this.dataList = response.body.list;
      this.pagination.total = response.body.total;
      let latestView = { path: "/operation/contestManage" };
      this.$router.push(latestView);
    },

    // 更新
    async updateQuestionState(row, defunct) {
      let jsonArray = [];
      let jsonObject = {};
      jsonObject.contest_id = row.contest_id;
      jsonObject.defunct = defunct;
      jsonArray.push(jsonObject);
      const response = await updateQuestionState(jsonArray);
      if (response && response.state === "success") {
        window.$msg("更新状态成功");
        this.getRaceContestList();
      }
    },

    // 重置
    reset() {
      this.searchForm.modelState = "";
      this.searchForm.defunct = "";
      this.searchForm.moduleTitle = "";
      this.searchForm.creatorName = "";
      this.searchForm.tagIdSet = [];
      this.searchForm.raceRule = "";
    },

    // 搜索
    search() {
      this.pagination.currentPage = 1;
      this.getRaceContestList();
    },

    add() {
      this.$router.push({
        path: "/operation/addcontest",
        query: {
          contestId: "-1",
          copyType: false,
        },
      });
    },
    copyContest(row) {
      this.$router.push({
        path: "/operation/addcontest",
        query: {
          contestId: row.contest_id,
          copyType: true,
        },
      });
    },
    async deleteContest(row) {
      let jsonArray = [];
      let jsonObject = {};
      jsonObject.contest_id = row.contest_id;
      jsonObject.is_delete = 1;
      jsonArray.push(jsonObject);
      const response = await updateQuestionState(jsonArray);
      if (response && response.state === "success") {
        window.$msg("删除题集成功");
        this.getRaceContestList();
      }
    },
    // 分页
    handleCurrentChange(val) {
      this.pagination.currentPage = val;
      this.getRaceContestList();
    },
    handleSizeChange(val) {
      this.pagination.pageSize = val;
      this.getRaceContestList();
    },

    // 删除
    deleteItem(row) {
      this.$confirm("是否删除当前题集", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          this.deleteContest(row);
        })
        .catch(() => {
          return false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../style/table";

.searchBg {
  padding: 8px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-left: 0px !important;
  margin-right: 0px !important;
  background-color: #ffffff;
}

.tabs-bg {
  background-color: #ffffff;
}

/deep/ .el-tabs__item.is-disabled {
  color: #222222 !important;
}

.operationClass {
  background-color: #ffffff;
  padding-left: 20px;
  padding-top: 15px;
  padding-bottom: 15px;
}

.flex-col {
  display: flex;
  align-items: center;

  span {
    display: inline-block;
    width: 120px;
    text-align: right;
    color: #606266;
  }
}
</style>
