var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-row",
    { staticClass: "table" },
    [
      _c(
        "el-tabs",
        { staticClass: "tb-tabs tabs-bg", attrs: { type: "card" } },
        [
          _c("el-tab-pane", { attrs: { disabled: "" } }, [
            _c("span", { attrs: { slot: "label" }, slot: "label" }, [
              _c("h3", [_vm._v("题集管理")]),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "el-row",
        { staticClass: "searchBg", attrs: { gutter: 24 } },
        [
          _c(
            "el-col",
            { staticClass: "flex-col", attrs: { span: 4 } },
            [
              _c("span", [_vm._v("题集标题：")]),
              _c("el-input", {
                attrs: { size: "small", placeholder: "输入题集名称" },
                nativeOn: {
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    return _vm.search.apply(null, arguments)
                  },
                },
                model: {
                  value: _vm.searchForm.moduleTitle,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.searchForm,
                      "moduleTitle",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "searchForm.moduleTitle",
                },
              }),
            ],
            1
          ),
          _c(
            "el-col",
            { staticClass: "flex-col", attrs: { span: 4 } },
            [
              _c("span", [_vm._v("题集ID：")]),
              _c("el-input", {
                staticStyle: { width: "150px" },
                attrs: {
                  size: "small",
                  placeholder: "输入题库ID",
                  onkeyup: "value=value.replace(/[^\\d]/g,'')",
                },
                nativeOn: {
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    return _vm.search.apply(null, arguments)
                  },
                },
                model: {
                  value: _vm.searchForm.creatorName,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.searchForm,
                      "creatorName",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "searchForm.creatorName",
                },
              }),
            ],
            1
          ),
          _c(
            "el-col",
            { staticClass: "flex-col", attrs: { span: 4 } },
            [
              _c("span", [_vm._v("状态：")]),
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择状态", size: "small" },
                  model: {
                    value: _vm.searchForm.defunct,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchForm, "defunct", $$v)
                    },
                    expression: "searchForm.defunct",
                  },
                },
                _vm._l(_vm.defuncteList, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.state, value: item.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { staticClass: "flex-col", attrs: { span: 4 } },
            [
              _c("span", [_vm._v("标签：")]),
              _c("el-cascader", {
                ref: "tagIdSet",
                staticStyle: { width: "170px" },
                attrs: {
                  "show-all-levels": false,
                  size: "small",
                  options: _vm.tagTreeData,
                  "collapse-tags": "",
                  placeholder: "请选择标签",
                  props: _vm.props,
                  filterable: "",
                  clearable: "",
                },
                model: {
                  value: _vm.searchForm.tagIdSet,
                  callback: function ($$v) {
                    _vm.$set(_vm.searchForm, "tagIdSet", $$v)
                  },
                  expression: "searchForm.tagIdSet",
                },
              }),
            ],
            1
          ),
          _c(
            "el-col",
            { staticClass: "flex-col", attrs: { span: 3 } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "small" },
                  on: { click: _vm.search },
                },
                [_vm._v("搜 索")]
              ),
              _c(
                "el-button",
                { attrs: { size: "small" }, on: { click: _vm.reset } },
                [_vm._v("重 置")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        { staticClass: "operationClass" },
        [
          _c(
            "el-button",
            {
              staticStyle: { "margin-left": "30px" },
              attrs: { size: "mini " },
              on: { click: _vm.add },
            },
            [_vm._v("新建题集 ")]
          ),
        ],
        1
      ),
      _c(
        "el-table",
        { staticClass: "tb-list", attrs: { data: _vm.dataList } },
        [
          _c("el-table-column", {
            attrs: {
              label: "题集ID",
              align: "left",
              prop: "contest_id",
              width: "100",
            },
          }),
          _c("el-table-column", {
            attrs: { label: "题集标题", width: "150" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-link",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.gotodetail(scope.row)
                          },
                        },
                      },
                      [
                        _c("span", { staticStyle: { color: "#1890ff" } }, [
                          _vm._v(_vm._s(scope.row.title)),
                        ]),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "类型(赛制)", align: "left", width: "180" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _c("span", [
                      _vm._v(" " + _vm._s(_vm.searchForm.raceType) + " "),
                      _vm.searchForm.raceType === "普通题集"
                        ? _c("span", [
                            _vm._v(" (" + _vm._s(row.raceRule) + ") "),
                          ])
                        : _vm._e(),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "题集标签" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-row",
                      {
                        staticStyle: { "max-height": "80px", overflow: "auto" },
                      },
                      _vm._l(scope.row.tagInfoList, function (item, index) {
                        return _c(
                          "el-tag",
                          {
                            key: index,
                            staticClass: "c-margin-left-8 c-margin-top-8",
                            attrs: { type: "warning" },
                          },
                          [_vm._v(_vm._s(item.title) + " ")]
                        )
                      }),
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "状态", align: "left", width: "80" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", {
                      style:
                        "color" + ":" + _vm.getStateColor(scope.row.defunct),
                      domProps: {
                        textContent: _vm._s(_vm.getState(scope.row.defunct)),
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "比赛时间", align: "left", width: "360" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.startTime &&
                    scope.row.startTime.indexOf("0001-01-01") === -1
                      ? _c("span", [
                          _vm._v(
                            _vm._s(scope.row.startTime.substring(0, 19)) +
                              " ~ " +
                              _vm._s(scope.row.endTime.substring(0, 19))
                          ),
                        ])
                      : _c("span", [_vm._v("不限时")]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "操作", align: "left", width: "120" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-dropdown",
                      { attrs: { trigger: "click" } },
                      [
                        _c("el-button", { attrs: { type: "text" } }, [
                          _vm._v(" 操 作 "),
                          _c("i", {
                            staticClass: "el-icon-arrow-down el-icon--right",
                          }),
                        ]),
                        _c(
                          "el-dropdown-menu",
                          { attrs: { slot: "dropdown" }, slot: "dropdown" },
                          [
                            scope.row.defunct === "Y"
                              ? _c(
                                  "el-dropdown-item",
                                  {
                                    attrs: {
                                      disabled:
                                        scope.row.role !== "管理者" &&
                                        scope.row.role !== "创建者",
                                    },
                                    nativeOn: {
                                      click: function ($event) {
                                        return _vm.updateQuestionState(
                                          scope.row,
                                          "N"
                                        )
                                      },
                                    },
                                  },
                                  [_c("span", [_vm._v("发布")])]
                                )
                              : _vm._e(),
                            scope.row.defunct === "N"
                              ? _c(
                                  "el-dropdown-item",
                                  {
                                    attrs: {
                                      disabled:
                                        scope.row.role !== "管理者" &&
                                        scope.row.role !== "创建者",
                                    },
                                    nativeOn: {
                                      click: function ($event) {
                                        return _vm.updateQuestionState(
                                          scope.row,
                                          "Y"
                                        )
                                      },
                                    },
                                  },
                                  [_c("span", [_vm._v("下架")])]
                                )
                              : _vm._e(),
                            _c(
                              "el-dropdown-item",
                              {
                                attrs: {
                                  disabled:
                                    scope.row.role !== "管理者" &&
                                    scope.row.role !== "创建者",
                                },
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.copyContest(scope.row)
                                  },
                                },
                              },
                              [_c("span", [_vm._v("复制")])]
                            ),
                            _c(
                              "el-dropdown-item",
                              {
                                attrs: {
                                  disabled:
                                    scope.row.role !== "管理者" &&
                                    scope.row.role !== "创建者",
                                },
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.deleteItem(scope.row)
                                  },
                                },
                              },
                              [_c("span", [_vm._v("删除")])]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("el-pagination", {
        attrs: {
          "current-page": _vm.pagination.currentPage,
          "page-sizes": _vm.pagination.pageSizes,
          "page-size": _vm.pagination.pageSize,
          layout: _vm.pagination.layout,
          total: _vm.pagination.total,
        },
        on: {
          "size-change": _vm.handleSizeChange,
          "current-change": _vm.handleCurrentChange,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }